"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWRewardToJSON = exports.OWRewardFromJSONTyped = exports.OWRewardFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OWRewardFromJSON(json) {
    return OWRewardFromJSONTyped(json, false);
}
exports.OWRewardFromJSON = OWRewardFromJSON;
function OWRewardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'game': !(0, runtime_1.exists)(json, 'game') ? undefined : (0, _1.OWGameFromJSON)(json['game']),
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'rewardType': (0, _1.OWRewardTypeFromJSON)(json['rewardType']),
        'rewardAmount': json['rewardAmount'],
        'rewardMax': !(0, runtime_1.exists)(json, 'rewardMax') ? undefined : json['rewardMax'],
        'rewardMetaData': !(0, runtime_1.exists)(json, 'rewardMetaData') ? undefined : json['rewardMetaData'],
        'rewardCategory': (0, _1.OWLogCategoryFromJSON)(json['rewardCategory']),
        'eventType': (0, _1.OWGameEventTypeFromJSON)(json['eventType']),
        'progress': !(0, runtime_1.exists)(json, 'progress') ? undefined : json['progress'],
        'target': json['target'],
        'milestones': !(0, runtime_1.exists)(json, 'milestones') ? undefined : (json['milestones'].map(_1.OWRewardMilestoneFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
    };
}
exports.OWRewardFromJSONTyped = OWRewardFromJSONTyped;
function OWRewardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'game': (0, _1.OWGameToJSON)(value.game),
        'name': value.name,
        'description': value.description,
        'rewardType': (0, _1.OWRewardTypeToJSON)(value.rewardType),
        'rewardAmount': value.rewardAmount,
        'rewardMax': value.rewardMax,
        'rewardMetaData': value.rewardMetaData,
        'rewardCategory': (0, _1.OWLogCategoryToJSON)(value.rewardCategory),
        'eventType': (0, _1.OWGameEventTypeToJSON)(value.eventType),
        'progress': value.progress,
        'target': value.target,
        'milestones': value.milestones === undefined ? undefined : (value.milestones.map(_1.OWRewardMilestoneToJSON)),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
    };
}
exports.OWRewardToJSON = OWRewardToJSON;
