"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DpayPurchaseGuestResponseToJSON = exports.DpayPurchaseGuestResponseFromJSONTyped = exports.DpayPurchaseGuestResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
function DpayPurchaseGuestResponseFromJSON(json) {
    return DpayPurchaseGuestResponseFromJSONTyped(json, false);
}
exports.DpayPurchaseGuestResponseFromJSON = DpayPurchaseGuestResponseFromJSON;
function DpayPurchaseGuestResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dpay': !(0, runtime_1.exists)(json, 'dpay') ? undefined : json['dpay'],
        'fiat': !(0, runtime_1.exists)(json, 'fiat') ? undefined : json['fiat'],
        'price': !(0, runtime_1.exists)(json, 'price') ? undefined : json['price'],
        'expiresAt': !(0, runtime_1.exists)(json, 'expiresAt') ? undefined : json['expiresAt'],
    };
}
exports.DpayPurchaseGuestResponseFromJSONTyped = DpayPurchaseGuestResponseFromJSONTyped;
function DpayPurchaseGuestResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dpay': value.dpay,
        'fiat': value.fiat,
        'price': value.price,
        'expiresAt': value.expiresAt,
    };
}
exports.DpayPurchaseGuestResponseToJSON = DpayPurchaseGuestResponseToJSON;
